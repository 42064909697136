<template>
  <div class="container mt-3">
    <h4 class="mb-3">Charges</h4>
    <div v-if="total > 0">
      <strong>Previous account balance: {{ financialFormat(previousBalance) }}</strong>
      <div v-if="charges.length > 0">Unpaid Charges:</div>
      <ul class="list-group">
        <li class="list-group-item d-flex align-items-center justify-content-between" v-for="charge in charges" :key="charge.id">{{ financialFormat(charge.amount) }} -- {{ charge.description }} <button class="btn btn-danger" @click="deleteCharge(charge)">Cancel</button></li>
      </ul>
      <hr>
      <strong>Total: {{ financialFormat(total) }}</strong>
      <payment-methods />
    </div>
    <p v-if="total <= 0">You have no outstanding charges.</p>
  </div>
</template>

<script lang="ts">
import Account from '@/types/account'
import Charge from '@/types/charge'
import { defineComponent } from 'vue'
import financialFormatMixin from '@/utils/financialFormatMixin'
import paymentMethods from '@/components/PaymentMethods.vue'

// show account (totalCharged - totalPaid) - (sum of charge.amount) = 'Previous account balance'
export default defineComponent({
  mixins: [financialFormatMixin],
  components: {
    paymentMethods: paymentMethods
  },
  data () {
    return {
      account: null as Account | null,
      charges: [] as Charge[]
    }
  },
  async mounted () {
    const response = await this.$api.getAccountWithCharges()
    if (response.account && response.charges) {
      // populate data, show payment method selection component
      this.account = response.account
      this.charges = response.charges
    }
  },
  computed: {
    total (): number {
      return (this.account?.totalCharged || 0) - (this.account?.totalPaid || 0)
    },
    chargesTotal (): number {
      let chargesTotal = 0
      this.charges.forEach(charge => {
        chargesTotal += charge.amount
      })
      return chargesTotal
    },
    previousBalance (): number {
      return this.chargesTotal - this.total
    }
  },
  methods: {
    async deleteCharge (deleteCharge: Charge) {
      if (!confirm('Are you sure you want to delete this charge and any related enrollments?')) {
        return
      }
      // remove charge from charges, subtract charge.amount from account.totalCharged
      const response = await this.$api.deleteCharge(deleteCharge.id)
      if (response.success) {
        this.charges = this.charges.filter(charge => charge.id !== deleteCharge.id)
        if (this.account) {
          this.account.totalCharged -= deleteCharge.amount
        }
      }
    }
  }
})
</script>
