<template>
  <div class="mt-5">
    <div v-if="chooseCard">
      <h4 class="mb-3">Select a payment card</h4>
      <form @submit.prevent="existingCard()">
        <div class="form-check mb-3" v-for="card in cards" :key="card.id">
          <input class="form-check-input" required v-model="selectedCard" type="radio" name="card" :id="card.id" :value="card.id">
          <label class="form-check-label" :for="card.id">{{ card.brand }} ending in {{ card.last4 }}</label>
        </div>
        <div class="mb-3">
          <a href="#" @click.prevent="showCardInput">Use a different card</a>
        </div>
        <button class="btn btn-success" :disabled="waiting" type="submit">Complete payment</button>
      </form>
    </div>
    <div v-show="!chooseCard">
      <h4 class="mb-3">Add a payment card</h4>
      <form @submit.prevent="newCard()">
        <div id="card-element"></div>
        <div class="mt-3"><a href="#" v-if="cards.length > 0" @click.prevent="showCardSelect">Use an existing card?</a></div>
        <div><button class="btn btn-success mt-3" type="submit" :disabled="waiting">Complete payment</button></div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Stripe, StripeCardElement } from '@stripe/stripe-js'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      waiting: false,
      chooseCard: true,
      selectedCard: '',
      cards: [],
      elementsMounted: false,
      cardElement: null as StripeCardElement | null,
      stripe: null as Stripe | null
    }
  },
  async mounted () {
    // use API to get existing cards, show add card button which replaces the displayed content with the card input form
    const response = await this.$api.getCards()
    if (response.cards) {
      this.cards = response.cards
      if (this.cards.length < 1) {
        this.showCardInput()
      }
    }
  },
  methods: {
    async showCardInput () {
      this.chooseCard = false
      if (!this.elementsMounted) {
        const stripe = await this.$api.getStripe()
        if (stripe) {
          this.stripe = stripe
          const elements = stripe.elements()
          this.cardElement = elements.create('card')
          this.cardElement.mount('#card-element')
        }
        this.elementsMounted = true
      }
    },
    showCardSelect () {
      this.chooseCard = true
    },
    async generateRecaptchaToken () {
      const token = await this.$recaptcha('pay')
      this.$api.setRecaptchaToken(token)
    },
    async newCard () {
      if (this.cardElement && this.stripe) {
        this.waiting = true
        const result = await this.stripe.createToken(this.cardElement)
        if (result.error) {
          this.waiting = false
          this.$store.commit('error', result)
        } else if (result.token) {
          await this.generateRecaptchaToken()
          const response = await this.$api.charge({ token: result.token.id })
          if (response.success) {
            this.$store.commit('success', { success: 'Payment complete!', preserve: true })
            this.$router.replace({ name: 'enrollments' })
          }
        }
      }
    },
    async existingCard () {
      this.waiting = true
      await this.generateRecaptchaToken()
      const response = await this.$api.charge({ cardId: this.selectedCard })
      if (response.success) {
        this.$store.commit('success', { success: 'Payment complete!', preserve: true })
        this.$router.replace({ name: 'enrollments' })
      }
    }
  }
})
</script>
